define("discourse/plugins/discourse-token-gate/discourse/connectors/user-preferences-nav/user-preferences-web3-wallets-link", ["exports", "@ember/routing", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _routing, _i18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UserPreferencesWeb3WalletsLink = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <LinkTo @route="preferences.wallets">
      {{i18n "web3.preferences.title"}}
    </LinkTo>
  
  */
  {
    "id": "EK3goYeN",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@route\"],[\"preferences.wallets\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[32,1],[\"web3.preferences.title\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-token-gate/discourse/connectors/user-preferences-nav/user-preferences-web3-wallets-link.js",
    "scope": () => [_routing.LinkTo, _i18n.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = UserPreferencesWeb3WalletsLink;
});